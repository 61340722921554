import React, { useEffect } from "react";
import Logo from "../Logo/Logo";
import Action from "../Action/Action";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Footer = () => {
  const windowWidth = window.innerWidth;

  useEffect(() => {
    if (windowWidth >= 1280) {
      gsap.to(".bs-footer--content", {
        duration: 3,
        opacity: 1,
        translateX: "25px",
        scrollTrigger: {
          trigger: ".bs-footer",
          start: "top bottom",
          // markers: true,
        },
      });

      gsap.to(".bs-footer--copyright", {
        delay: 2,
        duration: 3,
        opacity: ".45",
        scrollTrigger: {
          trigger: ".bs-footer",
          start: "top bottom",
          // markers: true,
        },
      });
    } else if (windowWidth >= 1024) {
      gsap.to(".bs-footer--content", {
        duration: 3,
        opacity: 1,
        translateX: "25px",
        scrollTrigger: {
          trigger: ".bs-footer",
          start: "top bottom",
          // markers: true,
        },
      });

      gsap.to(".bs-footer--copyright", {
        delay: 2,
        duration: 3,
        opacity: ".45",
        scrollTrigger: {
          trigger: ".bs-footer",
          start: "top bottom",
          // markers: true,
        },
      });
    } else {
      gsap.to(".bs-footer--content", {
        duration: 3,
        opacity: 1,
        translateX: "25px",
        scrollTrigger: {
          trigger: ".bs-footer",
          start: "top bottom",
          // markers: true,
        },
      });

      gsap.to(".bs-footer--copyright", {
        delay: 2,
        duration: 3,
        opacity: ".45",
        scrollTrigger: {
          trigger: ".bs-footer",
          start: "top bottom",
          // markers: true,
        },
      });
    }
  }, [windowWidth]);

  return (
    <div className="bs-footer">
      <div className="bs-footer--content">
        <div className="bs-footer--column logo">
          <Logo />
        </div>
        {windowWidth < 1024 ? (
          <div className="bs-footer--list_wrapper">
            <div className="bs-footer--column">
              <ul className="bs-footer--list">
                <li className="bs-footer--list_item header">Follow Us</li>
                <a
                  href="https://www.instagram.com/beignetspot/"
                  className="bs-footer--list_item"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </ul>
            </div>

            <div className="bs-footer--column">
              <ul className="bs-footer--list">
                <li className="bs-footer--list_item header">Contact Us</li>
                <a
                  href="mailto:contact@beignetspot.com"
                  className="bs-footer--list_item"
                >
                  contact@beignetspot.com
                </a>
                <a href="tel:+1(951)224-9830" className="bs-footer--list_item">
                  +1(951)224-9830
                </a>
              </ul>
            </div>

            <div className="bs-footer--column">
              <ul className="bs-footer--list">
                <li className="bs-footer--list_item header">Locations</li>
                <li className="bs-footer--list_item">4019 market St.</li>
                <li className="bs-footer--list_item">Riverside Ca, 92501</li>
              </ul>
            </div>

            <div className="bs-footer--column">
              <ul className="bs-footer--list">
                <li className="bs-footer--list_item header">Hours</li>
                <li className="bs-footer--list_item">Mon - Fri</li>
                <li className="bs-footer--list_item">8:00am - 5:00pm</li>
                <li className="bs-footer--list_item">Sat - Sun</li>
                <li className="bs-footer--list_item">8:00am - 5:00pm</li>
              </ul>
            </div>

            <div className="bs-footer--column">
              <ul className="bs-footer--list">
                <li className="bs-footer--list_item important">
                  Hot &amp; Ready
                </li>
                <li className="bs-footer--list_item important">
                  Beignets Served
                </li>
                <li className="bs-footer--list_item important">
                  To Order Daily!
                </li>
                <li className="bs-footer--list_item">
                  <Action
                    href="https://www.clover.com/online-ordering/beignet-spot-riverside-2"
                    label="Order Now"
                  />
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <>
            <div className="bs-footer--column">
              <ul className="bs-footer--list">
                <li className="bs-footer--list_item header">Follow Us</li>
                <a
                  href="https://www.instagram.com/beignetspot/"
                  className="bs-footer--list_item"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </ul>
            </div>

            <div className="bs-footer--column">
              <ul className="bs-footer--list">
                <li className="bs-footer--list_item header">Contact Us</li>
                <a
                  href="mailto:contact@beignetspot.com"
                  className="bs-footer--list_item"
                >
                  contact@beignetspot.com
                </a>
                <a href="tel:+1(951)224-9830" className="bs-footer--list_item">
                  +1(951)224-9830
                </a>
              </ul>
            </div>

            <div className="bs-footer--column">
              <ul className="bs-footer--list">
                <li className="bs-footer--list_item header">Locations</li>
                <li className="bs-footer--list_item">4019 market St.</li>
                <li className="bs-footer--list_item">Riverside Ca, 92501</li>
              </ul>
            </div>

            <div className="bs-footer--column">
              <ul className="bs-footer--list">
                <li className="bs-footer--list_item header">Hours</li>
                <li className="bs-footer--list_item">Mon - Fri</li>
                <li className="bs-footer--list_item">8:00am - 5:00pm</li>
                <li className="bs-footer--list_item">Sat - Sun</li>
                <li className="bs-footer--list_item">8:00am - 5:00pm</li>
              </ul>
            </div>

            <div className="bs-footer--column">
              <ul className="bs-footer--list">
                <li className="bs-footer--list_item important">
                  Hot &amp; Ready
                </li>
                <li className="bs-footer--list_item important">
                  Beignets Served
                </li>
                <li className="bs-footer--list_item important">
                  To Order Daily!
                </li>
                <li className="bs-footer--list_item">
                  <Action
                    href="https://www.clover.com/online-ordering/beignet-spot-riverside-2"
                    label="Order Now"
                  />
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
      <div className="bs-footer--copyright">
        <p className="bs-footer--copyright_company">
          &copy; {new Date().getFullYear()} Beignet Spot
        </p>
        <a
          href="https://www.studiosupermassive.com"
          className="bs-footer--copyright_ssm"
        >
          Site Designed By Studio Supermassive LLC.
        </a>
      </div>
    </div>
  );
};

export default Footer;
